import { AfterSuccess } from '@cosmos/state';
import type { SearchCriteria } from '@esp/common/types';
import type { Design } from '@esp/orders/types';

const ACTION_SCOPE = '[Companies Designs]';

export namespace CompaniesDesignsActions {
  export class SearchWithExistingCriteria {
    static readonly type = `${ACTION_SCOPE} Search With Existing Criteria`;
  }

  @AfterSuccess(SearchWithExistingCriteria)
  export class UpdateDesign {
    static readonly type = `${ACTION_SCOPE} Update Design`;

    constructor(readonly design: Design) {}
  }

  export class Download {
    static readonly type = `${ACTION_SCOPE} Download`;

    constructor(
      readonly urlPart: string,
      readonly designId: number
    ) {}
  }

  @AfterSuccess(SearchWithExistingCriteria)
  export class Delete {
    static readonly type = `${ACTION_SCOPE} Remove`;

    constructor(readonly designId: number) {}
  }

  export class Search {
    static readonly type = `${ACTION_SCOPE} Search`;

    constructor(readonly criteria: SearchCriteria) {}
  }

  export class PrepareCriteria {
    static readonly type = `${ACTION_SCOPE} Prepare Criteria`;

    constructor(readonly criteria: SearchCriteria) {}
  }
}
